"use strict";

class RateModel {
  constructor() {
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
    };
  }

  intSubmitReview(email, rating, reviewText) {
    return fetch(
      process.env.API_HOST +
        "/review/submit" +
        `?email=${encodeURIComponent(email)}`,
      {
        headers: this.requestHeaders,
        method: "POST",
        body: JSON.stringify({
          rating: rating,
          text: reviewText,
        }),
      }
    );
  }
}

export default RateModel;
