import React from "react";

import Layout from "../layouts/LayoutEmpty";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import FeedbackSectionContainer from "../containers/rate/FeedbackSectionContainer";

const PgR = () => {
  return (
    <Layout isShowHelp>
      <Seo
        title="Please Rate Your Experience with AMZScout"
        description="Make Amazon product research quick and easy with accurate research software from AMZScout. Analyze niches and find the right products to help you make money."
        page="rate"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <FeedbackSectionContainer />
    </Layout>
  );
};

export default PgR;
