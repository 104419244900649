"use strict";

import track from "../../tracking/analytics";

class AnalyticsController {
  constructor() {
    this.params = this.getParams();
    this.software = this.getSoftware();
    this.category = `${this.software}|Review`;
    this.action = "close";
  }

  encode(parametr) {
    return encodeURIComponent(parametr);
  }

  isBrowser() {
    return typeof window !== "undefined";
  }
  getParams() {
    if (!this.isBrowser()) return;
    return new URL(window.location.href).searchParams;
  }
  getSoftware() {
    if (!this.isBrowser()) return;
    return this.params.get("software") || "LANDING";
  }

  send(action, label) {
    return track(this.category, action, label, null, this.software);
  }

  handleClosing() {
    this.body = `category=${this.encode(this.category)}&action=${this.encode(
      this.action
    )}&label=&value=${null}&software=${this.encode(this.software)}`;
    const blob = new URLSearchParams(this.body);
    navigator.sendBeacon(`${process.env.HOST}/analytics/v1/events`, blob);
  }
  openListener() {
    window.addEventListener("unload", this.handleClosing.bind(this));
  }
  killListener() {
    window.removeEventListener("unload", this.handleClosing.bind(this));
  }
}

export default AnalyticsController;
