import React from "react";
import PropTypes from "prop-types";
import SvgWhiteLogo from "../../../assets/images/vector/components/common/whiteLogo.svg";
import FeedbackSectionRateUs from "./FeedbackSectionRateUs";
import FeedbackSectionOpinion from "./FeedbackSectionOpinion";
import FeedbackSectionNotFiveStars from "./FeedbackSectionNotFiveStars";
import FeedbackSectionFiveStars from "./FeedbackSectionFiveStars";
import "./index.scss";

const FeedbackSection = (props) => {
  const {
    switchForm,
    numberOfStars,
    webPlatformLinkHandler,
    handlerClickRating,
    handlerClickOpinionBtn,
    showStar,
    getWebPlatform,
    setReviewText,
    webPlatform,
  } = props;
  const getStyle = (style) => {
    switch (style) {
      case 1:
        return "";
      case 2:
        return "Opinion";
      case 3:
        return "NotFiveStars";
      case 4:
        return "FiveStars";
      default:
        return "";
    }
  };
  const getForm = () => {
    switch (switchForm) {
      case 1:
        return (
          <FeedbackSectionRateUs
            numberOfStars={numberOfStars}
            handlerClickRating={handlerClickRating}
            showStar={showStar}
          />
        );
      case 2:
        return (
          <FeedbackSectionOpinion
            numberOfStars={numberOfStars}
            setReviewText={setReviewText}
            handlerClickOpinionBtn={handlerClickOpinionBtn}
          />
        );

      case 3:
        return <FeedbackSectionNotFiveStars />;
      case 4:
        return (
          <FeedbackSectionFiveStars
            content={getWebPlatform(webPlatform)}
            webPlatformLinkHandler={webPlatformLinkHandler}
          />
        );
      default:
        return (
          <FeedbackSectionRateUs
            numberOfStars={numberOfStars}
            handlerClickRating={handlerClickRating}
            showStar={showStar}
          />
        );
    }
  };

  return (
    <section className="PgR-FeedbackSection" id="feedbackSection">
      <div className="PgR-FeedbackSection__wrapper">
        <div className="PgR-FeedbackSection__inner">
          <div
            className={`PgR-FeedbackSection__container PgR-FeedbackSection__container${getStyle(
              switchForm
            )}`}
          >
            <img
              className="PgR-FeedbackSection__logo"
              src={SvgWhiteLogo}
              alt=""
            />
            <div className="PgR-FeedbackSection__wrapperContent">
              {getForm()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
FeedbackSection.propTypes = {
  switchForm: PropTypes.number,
  numberOfStars: PropTypes.number,
  handlerAnalytics: PropTypes.func,
  handlerClickRating: PropTypes.func,
  handlerClickOpinionBtn: PropTypes.func,
  showStar: PropTypes.func,
  setReviewText: PropTypes.func,
  getWebPlatform: PropTypes.func,
};
export default FeedbackSection;
