import React from "react";
import PropTypes from "prop-types";
import SvgFilledStar from "../../../assets/images/vector/pages/rate/ratePage-filledStar.svg";
import SvgEmptyStar from "../../../assets/images/vector/pages/rate/ratePage-emptyStar.svg";
import "./index.scss";

const FeedbackSectionRating = (props) => {
  const { numberOfStars, handlerClickRating, showStar, addAttributes } = props;
  const rating = [];
  for (let i = 0; i < 5; i++) {
    rating.push(
      addAttributes ? (
        <li
          key={"rating_" + i}
          onClick={() => handlerClickRating(i)}
          onMouseEnter={() => showStar(i)}
        >
          {numberOfStars < i ? (
            <img id={i} src={SvgEmptyStar} alt="" />
          ) : (
            <img id={i} src={SvgFilledStar} alt="" />
          )}
        </li>
      ) : (
        <li key={"rating_" + i}>
          {numberOfStars < i ? (
            <img id={i} src={SvgEmptyStar} alt="" />
          ) : (
            <img id={i} src={SvgFilledStar} alt="" />
          )}
        </li>
      )
    );
  }
  return addAttributes ? (
    <ul
      className="PgR-FeedbackSection__Rating"
      onMouseLeave={() => showStar(-1)}
    >
      {rating}
    </ul>
  ) : (
    <ul className="PgR-FeedbackSection__Rating">{rating}</ul>
  );
};
FeedbackSectionRating.propTypes = {
  numberOfStars: PropTypes.number,
  handlerClickRating: PropTypes.func,
  showStar: PropTypes.func,
  addAttributes: PropTypes.bool,
};
export default FeedbackSectionRating;
