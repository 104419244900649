import React from "react";
import "./index.scss";

const FeedbackSectionNotFiveStars = () => {
  return (
    <div className="PgR-FeedbackSection__NotFiveStars">
      <div className="PgR-FeedbackSection__NotFiveStars-strOne">
        Thank you for you feedback!
      </div>
      <div className="PgR-FeedbackSection__NotFiveStars-strTwo">
        Your reviews keep our team motivated to improve AMZScout's services!
      </div>
      <a
        href="/app/#/auth/login?redirect=%2Fcourse"
        className="PgR-FeedbackSection__NotFiveStars-btn"
        target="_self"
      >
        Enter your AMZScout account
      </a>
    </div>
  );
};
export default FeedbackSectionNotFiveStars;
