import React from "react";
import PropTypes from "prop-types";
import FeedbackSectionRating from "./FeedbackSectionRating";
import "./index.scss";

const FeedbackSectionFiveStars = (props) => {
  const { content, webPlatformLinkHandler } = props;
  return (
    <div className="PgR-FeedbackSection__FiveStars">
      <FeedbackSectionRating numberOfStars={5} addAttributes={false} />
      <div className="PgR-FeedbackSection__FiveStars-strOne">
        Your reviews keep our team motivated to improve AMZScout's services!
      </div>
      <div className="PgR-FeedbackSection__FiveStars-strTwo">
        {content.secondString}
      </div>
      <a
        onClick={webPlatformLinkHandler("review_site", content.label)}
        href={content.link}
        className="PgR-FeedbackSection__FiveStars-btn"
        target="_self"
      >
        {content.textButton}
      </a>
    </div>
  );
};
FeedbackSectionFiveStars.propTypes = {
  content: PropTypes.object,
  handlerAnalytics: PropTypes.func,
};
export default FeedbackSectionFiveStars;
