"use strict";

import RateModel from "../../models/rate/rateModel";

export default class RateController {
  constructor() {
    this.rateModel = new RateModel();
    this.params = this.getParams();
    this.email = this.getEmail();
  }
  isBrowser() {
    return typeof window !== "undefined";
  }
  getParams() {
    return this.isBrowser() ? new URL(window.location.href).searchParams : null;
    // if (!this.isBrowser()) return
    // return new URL(window.location.href).searchParams;
  }
  getEmail() {
    return this.isBrowser() ? this.params.get("email") : null;
    // if (!this.isBrowser()) return
    // return this.params.get('email');
  }
  submitReview(rating, getWebPlatform, reviewText) {
    if (!this.email) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`email_undefined`);
    }

    return this.rateModel
      .intSubmitReview(this.email, rating, reviewText)
      .then((res) => {
        if (getWebPlatform)
          return res.text().then((text) => {
            return JSON.parse(text);
          });
      });
  }
}
