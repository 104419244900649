import React, { useEffect, useState } from "react";
import RateController from "../../controllers/rate/rateController";
import AnalyticsController from "../../controllers/rate/analyticsController";
import FeedbackSection from "../../components/rate/FeedbackSection";
import PG_R_FEEDBACK_CONTENT from "../../components/rate/FeedbackSection/index.content";
import "../../components/rate/FeedbackSection/index.scss";

const rateController = new RateController();
const analyticsController = new AnalyticsController();

const FeedbackSectionContainer = () => {
  const { Chrome_Store, Trustpilot } = PG_R_FEEDBACK_CONTENT;

  const [reviewText, setReviewText] = useState("");
  const [numberOfStars, setNumberOfStars] = useState(-1);
  const [switchForm, setSwitchForm] = useState(1);
  const [webPlatform, setWebPlatform] = useState();
  const [error, setError] = useState(false);
  const handlerAnalytics = (action, label) => {
    analyticsController.send(action, label);
  };

  const reviewSubmissionProcessor = (rating, onSwitchForm, getWebPlatform) => {
    rateController
      .submitReview(rating, getWebPlatform, reviewText)
      .then((res) => {
        setError(false);
        if (onSwitchForm) {
          setWebPlatform(res);
          setSwitchForm(4);
        }
      })
      .catch((err) => {
        console.log(err);
        err === "email_undefined" && alert("missing key parameter");
        setError(true);
      });
  };

  const handlerClickRating = (i) => {
    setNumberOfStars(i);
    i < 4 ? setSwitchForm(2) : reviewSubmissionProcessor(i + 1, true, true);
  };

  const handlerClickOpinionBtn = () => {
    reviewSubmissionProcessor(numberOfStars + 1, false, false);
    if (!error) {
      setSwitchForm(3);
      handlerAnalytics("amzscout_review", numberOfStars + 1);
      analyticsController.killListener();
    }
  };

  const webPlatformLinkHandler = (action, label) => {
    handlerAnalytics(action, label);
    analyticsController.killListener();
  };

  const showStar = (i) => {
    if (!setNumberOfStars) return;
    setTimeout(() => {
      setNumberOfStars(i);
    }, 150);
  };

  const getWebPlatform = (webPlatform) => {
    if (webPlatform === "TRUSTPILOT") return Trustpilot;
    // if (webPlatform === "FACEBOOK") return Facebook
    if (webPlatform === "CHROME_STORE") return Chrome_Store;
  };

  useEffect(() => {
    handlerAnalytics("show", "");
    analyticsController.openListener();
  }, []);

  return (
    <FeedbackSection
      switchForm={switchForm}
      numberOfStars={numberOfStars}
      webPlatformLinkHandler={webPlatformLinkHandler}
      handlerClickRating={handlerClickRating}
      handlerClickOpinionBtn={handlerClickOpinionBtn}
      showStar={showStar}
      getWebPlatform={getWebPlatform}
      setReviewText={setReviewText}
      webPlatform={webPlatform}
    />
  );
};
export default FeedbackSectionContainer;
