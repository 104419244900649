import React from "react";
import PropTypes from "prop-types";
import FeedbackSectionRating from "./FeedbackSectionRating";
import "./index.scss";

const FeedbackSectionOpinion = (props) => {
  const { numberOfStars, setReviewText, handlerClickOpinionBtn } = props;
  return (
    <div className="PgR-FeedbackSection__Opinion">
      <FeedbackSectionRating
        numberOfStars={numberOfStars}
        addAttributes={false}
      />
      <div className="PgR-FeedbackSection__Opinion-strOne">
        Thank you for your opinion!
      </div>
      <div className="PgR-FeedbackSection__Opinion-strTwo">
        Please share more detail about what you like and what we could improve
      </div>
      <textarea
        className="PgR-FeedbackSection__Opinion-textarea"
        autoFocus="autofocus"
        required
        maxLength="500"
        onInput={(e) => {
          setReviewText(e.target.value);
        }}
      />
      <div
        className="PgR-FeedbackSection__Opinion-btn"
        onClick={() => handlerClickOpinionBtn()}
      >
        Send Feedback
      </div>
    </div>
  );
};
FeedbackSectionOpinion.propTypes = {
  numberOfStars: PropTypes.number,
  handlerClickOpinionBtn: PropTypes.func,
  setReviewText: PropTypes.func,
};
export default FeedbackSectionOpinion;
