import React from "react";
import PropTypes from "prop-types";
import FeedbackSectionRating from "./FeedbackSectionRating";
import "./index.scss";

const FeedbackSectionRateUs = (props) => {
  const { numberOfStars, handlerClickRating, showStar } = props;
  return (
    <div className="PgR-FeedbackSection__RateUs">
      <div className="PgR-FeedbackSection__RateUs-strOne">
        Your opinion means a lot to us
      </div>
      <div className="PgR-FeedbackSection__RateUs-strTwo">
        Please share about your experience with AMZScout
      </div>
      <div className="PgR-FeedbackSection__RateUs-strThree">
        Rate us on a 1-5 star scale
      </div>
      <FeedbackSectionRating
        numberOfStars={numberOfStars}
        handlerClickRating={handlerClickRating}
        showStar={showStar}
        addAttributes={true}
      />
    </div>
  );
};
FeedbackSectionRateUs.propTypes = {
  numberOfStars: PropTypes.number,
  handlerClickRating: PropTypes.func,
  showStar: PropTypes.func,
};

export default FeedbackSectionRateUs;
